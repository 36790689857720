// extracted by mini-css-extract-plugin
export var pills = "pills-module--pills--q8Dpb";
export var pill = "pills-module--pill--jSAUu";
export var pillRed = "pills-module--pill--red--W10bc";
export var pillPink = "pills-module--pill--pink--2kMtb";
export var pillPurple = "pills-module--pill--purple--+uEC1";
export var pillDeepPurple = "pills-module--pill--deep-purple--oR456";
export var pillIndigo = "pills-module--pill--indigo--zbWLB";
export var pillBlue = "pills-module--pill--blue--uMk+0";
export var pillLightBlue = "pills-module--pill--light-blue--Giuk7";
export var pillCyan = "pills-module--pill--cyan--lvXFb";
export var pillTeal = "pills-module--pill--teal--1EGKC";
export var pillGreen = "pills-module--pill--green--UBhNs";
export var pillLightGreen = "pills-module--pill--light-green--YzMOB";
export var pillLime = "pills-module--pill--lime--1lZsG";
export var pillYellow = "pills-module--pill--yellow--HZZmI";
export var pillAmber = "pills-module--pill--amber--5rCzg";
export var pillOrange = "pills-module--pill--orange--NaZ1-";
export var pillDeepOrange = "pills-module--pill--deep-orange--RP41Y";
export var pillBrown = "pills-module--pill--brown--C9nbB";
export var pillGrey = "pills-module--pill--grey--ICnjs";
export var pillBlueGrey = "pills-module--pill--blue-grey--iJ0wg";